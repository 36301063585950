// src/helpers/getEndpoint.js
const getEndpoint = (countryCode, configData) => {
    switch (countryCode) {
      case "id":
        return configData.mulesoftConfig.microserviceEndpointId;
      case "idic":
        return configData.mulesoftConfig.microserviceEndpointIdIc;
      case "pk":
        return configData.mulesoftConfig.microserviceEndpointPk;
      default:
        return configData.mulesoftConfig.microserviceEndpoint;
    }
  };
  
  export default getEndpoint;
  
  