// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomToast_toast-container__l38ir {
  background-color: #59b876; }

.CustomToast_toast-content__xklWA {
  display: flex;
  align-items: center; }

.CustomToast_toast-icon__e0DLe {
  margin: 10px; }

.CustomToast_toast-message__h7-3i {
  margin: 10px 0; }

.CustomToast_close-button-container__fGjt\\+ {
  display: flex;
  justify-content: center;
  align-items: center; }
`, "",{"version":3,"sources":["webpack://./src/helper/Toast/CustomToast.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAA;;AAG3B;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,YAAY,EAAA;;AAGd;EACE,cAAc,EAAA;;AAGhB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA","sourcesContent":[".toast-container {\n  background-color: #59b876;\n}\n\n.toast-content {\n  display: flex;\n  align-items: center;\n}\n\n.toast-icon {\n  margin: 10px;\n}\n\n.toast-message {\n  margin: 10px 0;\n}\n\n.close-button-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast-container": `CustomToast_toast-container__l38ir`,
	"toast-content": `CustomToast_toast-content__xklWA`,
	"toast-icon": `CustomToast_toast-icon__e0DLe`,
	"toast-message": `CustomToast_toast-message__h7-3i`,
	"close-button-container": `CustomToast_close-button-container__fGjt+`
};
export default ___CSS_LOADER_EXPORT___;
