import axios from 'axios';
import { LogoutInvalidToken, RefreshTokenReload, getCookie, getUserAgent } from '../utils/utils';
import configData from './configData.json';
import { LOGOUT_ERROR, NON_SSO } from '../constants/login-constants';
import { getSendVAPTHeaders } from '../helper/vaptHelper';

const HTTP_METHODS = Object.freeze({
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
});

const getMicroserviceEndpoint = (countryCode) => {
    switch (countryCode) {
        case 'id':
            return configData.mulesoftConfig.microserviceEndpointId;
        case 'idic':
            return configData.mulesoftConfig.microserviceEndpointIdIc;
        case 'pk':
            return configData.mulesoftConfig.microserviceEndpointPk;
        case 'bd':
            return configData.mulesoftConfig.microserviceEndpointPk;
        default:
            return configData.mulesoftConfig.microserviceEndpoint;
    }
};

const MicroservicePostFetchUtil = (() => {
    const dataCache = {};

    const defaultConfig = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const clientConfigs = {
        "client_id": configData.mulesoftConfig.client_id,
        "client_secret":configData.mulesoftConfig.client_secret,
     "countryCode": configData.common.vn.countryCode
    };
    let controller = new AbortController();

    const stringToHex = (str) => {
        let arr = [];
        for (let i = 0; i < str.length; i++) {
            arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
        }
        return arr.join('');
        
    };


    const mainRequest = async (url, method, customerToken,isOfflineApp,flag = '') => {
        
        const userID = localStorage.getItem("email");
        const userAgent = await getUserAgent();        
        let loginType = localStorage.getItem("loginType") || NON_SSO;
        let  myHeaders = new Headers();
        myHeaders.append("client_id", configData.mulesoftConfig.client_id);
        myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
        myHeaders.append("customerToken", customerToken.customerToken);
        
        const appendCountryCode = (countryCode) => {
          if (countryCode === "idic") {
            myHeaders.append("countryCode", "id");
            myHeaders.append("BusinessGroup", "ic");
          } else {
            myHeaders.append("countryCode", countryCode);
          }
        };

        if (customerToken?.checkStatus) {
          appendCountryCode(customerToken?.countryCode);
          myHeaders.append("interfaceName", customerToken?.interfaceName);
          myHeaders.append("fileName", customerToken?.fileName);
          myHeaders.append("folderPath", customerToken?.folderName);
        } else if (customerToken?.downloadApi) {
          appendCountryCode(customerToken?.countryCode);
          myHeaders.append("folderPath", customerToken?.folderPath);
        } else {
          if (customerToken.countryCode === "idic") {
            myHeaders.append("countryCode", "id");
            myHeaders.append("BusinessGroup", "ic");
          } else {
            appendCountryCode(customerToken?.countryCode);
          }
        }

        if(getSendVAPTHeaders(customerToken?.countryCode) || flag === 'session') {
            myHeaders.append("X-User-ID", userID);
            myHeaders.append("X-Device-ID", userAgent);
            myHeaders.append("X-Login-Type", loginType);
        }

        let requestOptions = {
          method,
          headers: myHeaders,
          redirect: 'follow'
        };
            let baseURL;

            switch (flag) {
                case 'expAPI':
                    baseURL = configData.mulesoftConfig.muleExpEndpoint;
                    break;
                case 'qaAPI':
                    baseURL = customerToken.countryCode === "idic"  
                                ? configData.mulesoftConfig.muleOfflineEndpointIdIc
                                : configData.mulesoftConfig.muleOfflineEndpoint;
                    break;
                default:
                    baseURL = getMicroserviceEndpoint(customerToken.countryCode);;
                    break;
            }
            
        const finalURL = `${baseURL}${url}`;
    
        const req = new Request(finalURL, requestOptions);
        console.log("req", req);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            if(isOfflineApp){
                                return response;
                            }else{
                            return response.json();
                            }
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data?.errors?.errorPayload?.error?.message ==="INVALID_ID_TOKEN" 
                        || data.status === 407
                    ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
                        LogoutInvalidToken();
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(new Error(error));
                });
        });
    }

    const postRequest = async (url, method, customerToken, formData, onDownloadProgress, flag = '') => {
        const userID = localStorage.getItem("email");
        const userAgent = await getUserAgent();
        let loginType = localStorage.getItem("loginType");
        let myHeaders = new Headers();
        myHeaders.append("client_id", configData.mulesoftConfig.client_id);
        myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
        myHeaders.append("customerToken", customerToken.customerToken);
        myHeaders.append("interfaceName", customerToken.interfaceName);
        if(customerToken.countryCode === "idic"){
            myHeaders.append("countryCode", "id");
            myHeaders.append("BusinessGroup","ic");
            }else{
            myHeaders.append("countryCode", customerToken.countryCode);
            }   

            if(getSendVAPTHeaders(customerToken?.countryCode)) {
                myHeaders.append("X-User-ID", userID);
                myHeaders.append("X-Device-ID", userAgent);
                myHeaders.append("X-Login-Type", loginType);
            }   
    
        let requestOptions = onDownloadProgress 
            ? {
                method,
                headers: myHeaders,
                data: formData,
                onDownloadProgress, 
              }
            : {
                method,
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
              };
              let baseURL;

              if (flag === 'expAPI') {
                baseURL = configData.mulesoftConfig.muleExpEndpoint;
              } else if (flag === 'qaAPI') {
                baseURL = customerToken.countryCode === "idic"  
                            ? configData.mulesoftConfig.muleOfflineEndpointIdIc
                            : configData.mulesoftConfig.muleOfflineEndpoint;
              } else {
                baseURL = getMicroserviceEndpoint(customerToken.countryCode);
              }
            
        const finalURL = `${baseURL}${url}`;
        const req = new Request(finalURL, requestOptions);
        console.log("req", req);
        return onDownloadProgress 
            ? axios(finalURL, requestOptions ,onDownloadProgress)
                .then(response => {
                    if (response) {
                        if (response.status >= 200 && response.status <= 299) {
                            return response;
                        }
                        else if(response.status === 404){
                            return response;
                        } 
                        else if(response.status >= 500){
                            return response;
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data?.errors?.errorPayload?.error?.message ==="INVALID_ID_TOKEN" 
                        || data.status === 407
                    ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
                        LogoutInvalidToken();
                      }
                    dataCache[stringToHex(req)] = data;
                    return data["data"];
                })
            : new Promise((resolve, reject) => {
                fetch(req)
                    .then(async (response) => {
                        if (response) {
                            if (response.ok && response.status >= 200 && response.status <= 299) {
                                return response.json();
                            }
                            else if(response.status === 404){
                                return response.json();
                            } 
                            else if(response.status >= 500){
                                return response.json();
                            } 
                            else {
                                const result = await response.json();
                                throw result;
                            }
                        }
                    })
                    .then((data) => {
                        if(data.statusCode >= 500 && data?.errors?.errorPayload?.error?.message ==="INVALID_ID_TOKEN" 
                            || data.status === 407
                         ){
                            const refreshToken=getCookie("refreshToken");
                            if(refreshToken){
                                RefreshTokenReload(refreshToken);
                            }else{
                                LogoutInvalidToken();
                            }
                          } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
                            LogoutInvalidToken();
                          }
                        dataCache[stringToHex(req)] = data;
                        resolve(data);
                    }, (error) => {
                        reject(error);
                    });
            });
    }

    const request = async (url, method, headerConfig, body) => {
        const userID = localStorage.getItem("email");
        const userAgent = await getUserAgent();
        let loginType = localStorage.getItem("loginType");

        const vAPTHeaderConfig = {
            "X-User-ID": userID,
            "X-Device-ID": userAgent,
            "X-Login-Type": loginType,
        };

        const headers = {
          ...defaultConfig,
          ...clientConfigs,
        //   "action":actionType,
          ...headerConfig,
          ...(getSendVAPTHeaders(headerConfig.countryCode) ? vAPTHeaderConfig : {})
        };
        const requestConfig = {
            method,
            headers,
            body,
            signal: controller.signal,
        };
        console.log(headers,requestConfig)
        if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
            requestConfig.body =JSON.stringify(body)
        }
        const finalURL = `${getMicroserviceEndpoint(headerConfig.countryCode)}${url} `
        const req = new Request(finalURL, requestConfig);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            return response.json();
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data?.errors?.errorPayload?.error?.message ==="INVALID_ID_TOKEN" 
                        || data.status === 407
                    ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
                        LogoutInvalidToken();
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(new Error (error));
                });
        });
    };

    const abort = () => controller.abort();

    return {
        post: (url, customerToken, formData, flag, onDownloadProgress) => {
            return postRequest(url, HTTP_METHODS.POST, customerToken, formData, onDownloadProgress, flag);
        },
        get: (url, customerToken, flag, isOfflineApp) => {
            return mainRequest(url, HTTP_METHODS.GET, customerToken, isOfflineApp, flag);
        },
        put: (url, body, headerConfig = {}) => {
            return request(url, HTTP_METHODS.PUT, headerConfig, body);
        },
        delete: (url, headerConfig = {}) => {
            return request(url, HTTP_METHODS.DELETE, headerConfig);
        },
        abort: () => {
            abort();
            controller = new AbortController();
        }
    }
})();

export default MicroservicePostFetchUtil;