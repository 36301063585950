import { useEffect, useRef } from "react";
import GaEventHelper from '../helper/ga-event-helper';

let isGAOptimizeGTMLoaded = false;
export const useThirdPartyInjection = () => {

  const elementRef = useRef(document.querySelector('body'));


  const injectJsScript = () => {
    GaEventHelper.init();
  }

  const viewEvent = (type) => {
    if (!elementRef.current.dataset.thirdPartyLoaded) {
      injectJsScript();
      elementRef.current.dataset.thirdPartyLoaded = "true";
      console.log(type);

      // if all third party JS is triggerd remove all listener
      elementRef.current.removeEventListener('mousedown', () => {
        viewEvent('mousedown')
      });
      elementRef.current.removeEventListener('mousemove', () => {
        viewEvent('mousemove')
      });
      elementRef.current.removeEventListener('touchstart', () => {
        viewEvent('touchstart')
      });
      elementRef.current.removeEventListener('scroll', () => {
        viewEvent('scroll')
      });
      elementRef.current.removeEventListener('keydown', () => {
        viewEvent('keydown')
      });
    }
  }

  useEffect(() => {
    /*const configData = MARKET_SPECIFIC_DATA;
    const { config = {} } = configData || {};
    const { optimize = {} } = config || {};
    const { vwo = {} } = config || {};
    const { isEnabled: isEnabledGoogleOptimize  = false } = optimize || {};
    const { isEnabled: isEnabledVwo = false } = vwo || {};
    */
    elementRef.current.addEventListener('mousedown', () => {
      viewEvent('mousedown')
    });
    elementRef.current.addEventListener('mousemove', () => {
      viewEvent('mousemove')
    });
    elementRef.current.addEventListener('touchstart', () => {
      viewEvent('touchstart')
    });
    elementRef.current.addEventListener('scroll', () => {
      viewEvent('scroll')
    });
    elementRef.current.addEventListener('keydown', () => {
      viewEvent('keydown')
    });
    
    if (!isGAOptimizeGTMLoaded) {
      
      isGAOptimizeGTMLoaded = true;
    }

  }, []);

  return null;
};