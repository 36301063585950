import Indonesia from "../../../assets/images/Indonesia.svg";
import Vietnam from "../../../assets/images/vietnam.svg";
import Thailand from "../../../assets/images/Thailand.svg";
import Philippines from "../../../assets/images/Phillipines.svg";
import Pakistan from "../../../assets/images/Pakistan.svg";
import Bangladesh from "../../../assets/images/Bangladesh.svg";

export const getCountry = (selectedCountryCode) => {
    switch (selectedCountryCode) {
      case "id":
      case "idic":
        return Indonesia;
      case "vn":
        return Vietnam;
      case "ph":
        return Philippines;
      case "pk":
        return Pakistan;
      case "bn":
        return Bangladesh;
      default:
        return Thailand;
    }
  }


export const getCountryName = (selectedCountryCode, isHPC) => {
    if (selectedCountryCode === "id") {
      return isHPC ? "Indonesia HPC" : "Indonesia";
    }
    switch (selectedCountryCode) {
      case "idic":
        return "Indonesia IC";
      case "vn":
        return "Vietnam";
      case "ph":
        return "Philippines";
      case "pk":
        return "Pakistan";
      case "bn":
        return "Bangladesh";
      default:
        return "Thailand";
    }
  }
  