import {
  LogoutInvalidToken,
  RefreshTokenReload,
  getCookie,
  getUserAgent,
} from "../utils/utils";
import configData from "./configData.json";
// const configObj = config.get("configsGlobal");
import getEndpoint from "../helper/microservice-helper";
import { getSendVAPTHeaders } from "../helper/vaptHelper";
import { LOGOUT_ERROR } from "../constants/login-constants";

const HTTP_METHODS = Object.freeze({
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
});

const MicroserviceFetchUtil = (() => {
  const dataCache = {};
  const defaultConfig = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let controller = new AbortController();

  const stringToHex = (str) => {
    var arr = [];
    for (var i = 0; i < str.length; i++) {
      arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
    }
    return arr.join("");
  };

  const mainRequest = async (url, method, customerToken, isDownload) => {
    const countryBeforeReplacement = customerToken.countryCode;
    const userID = localStorage.getItem("email");
    const userAgent = await getUserAgent();
    let loginType = localStorage.getItem("loginType");
    var myHeaders = new Headers();
    myHeaders.append("client_id", configData.mulesoftConfig.client_id);
    myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
    myHeaders.append("customerToken", customerToken.customerToken);
    if(customerToken.countryCode === "idic"){
      customerToken.countryCode = "id";
      myHeaders.append("countryCode", customerToken.countryCode);
      myHeaders.append("BusinessGroup","ic");
    }else{
      myHeaders.append("countryCode", customerToken.countryCode);
    }

    if (customerToken.interfaceName) {
      myHeaders.append("interfaceName", customerToken.interfaceName);
    }

    if(getSendVAPTHeaders(customerToken?.countryCode)) {
      myHeaders.append("X-User-ID", userID);
      myHeaders.append("X-Device-ID", userAgent);
      myHeaders.append("X-Login-Type", loginType);
    }

    var requestOptions = {
      method,
      headers: myHeaders,
      redirect: "follow",
    };
    const endpoint = getEndpoint(countryBeforeReplacement, configData);
    const finalURL = `${endpoint}${url}`;
  
    const req = new Request(finalURL, requestOptions);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return isDownload ? response : response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else if (response.status === 400) {
              return response.json();
            }else {
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data?.errors?.errorPayload?.error?.message === "INVALID_ID_TOKEN"
              || data.status === 407
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
              LogoutInvalidToken();
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const putRequest = async (url, method, headerConfig, customerToken, body, app) => {
    const userID = localStorage.getItem("email");
    const userAgent = await getUserAgent();
    let loginType = localStorage.getItem("loginType");
    const countryBeforeReplacement = customerToken.countryCode;
    const clientConfigss = {
      client_id: configData.mulesoftConfig.client_id,
      client_secret: configData.mulesoftConfig.client_secret,
      customerToken: customerToken.customerToken,
      countryCode: customerToken.countryCode,
    };
    const vAPTHeaderConfig = {
      "X-User-ID": userID,
      "X-Device-ID": userAgent,
      "X-Login-Type": loginType,
    };

    if (customerToken.countryCode === "idic") {
      customerToken.countryCode = "id";
      clientConfigss.countryCode = customerToken.countryCode;
      clientConfigss.businessGroup = "ic";
    }

    const headers =
      app === "DeviceGeoCompliance"
        ? {
            //   ...defaultConfig,
            ...clientConfigss,
            //   "action":actionType,
            ...headerConfig,
            ...(getSendVAPTHeaders(customerToken?.countryCode) ? vAPTHeaderConfig : {})
          }
        : {
            ...defaultConfig,
            ...clientConfigss,
            //   "action":actionType,
            ...headerConfig,
            ...(getSendVAPTHeaders(customerToken?.countryCode) ? vAPTHeaderConfig : {})
          };
    const requestConfig = {
      method,
      headers,
      body,
      signal: controller.signal,
    };
    console.log(headers, requestConfig);
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      requestConfig.body = JSON.stringify(body);
    }
    const endpoint = getEndpoint(countryBeforeReplacement, configData);
    const finalURL = `${endpoint}${url}`;

    const req = new Request(finalURL, requestConfig);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else {
              console.log(response);
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data?.errors?.errorPayload?.error?.message === "INVALID_ID_TOKEN"
              || data.status === 407
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
              LogoutInvalidToken();
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const request = async (url, method, headerConfig, body) => {
    const countryBeforeReplacement = headerConfig.countryCode;
    const userID = localStorage.getItem("email");
    const userAgent = await getUserAgent();
    let loginType = localStorage.getItem("loginType");
    const clientConfigs = {
      client_id: configData.mulesoftConfig.client_id,
      client_secret: configData.mulesoftConfig.client_secret,
      countryCode: headerConfig.countryCode,
    };
    console.log("")
    if (headerConfig.countryCode === "idic") {
      headerConfig.countryCode  = "id";
      clientConfigs.countryCode = headerConfig.countryCode;
      clientConfigs.businessGroup = "ic";
    }
    const vAPTHeaderConfig = {
      "X-User-ID": userID,
      "X-Device-ID": userAgent,
      "X-Login-Type": loginType,
    };

    const headers = getSendVAPTHeaders(countryBeforeReplacement) 
      || url === "sessions/v1/storeSession" 
      ? {
        ...defaultConfig,
        ...clientConfigs,
        //   "action":actionType,
        ...headerConfig,
        ...vAPTHeaderConfig
      } : {
        ...defaultConfig,
        ...clientConfigs,
        //   "action":actionType,
        ...headerConfig
      };
    const requestConfig = {
      method,
      headers,
      body,
      signal: controller.signal,
    };
    console.log(headers, requestConfig);
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      requestConfig.body = JSON.stringify(body);
    }
    const endpoint = getEndpoint(countryBeforeReplacement, configData);
    const finalURL = `${endpoint}${url}`;

    const req = new Request(finalURL, requestConfig);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else {
              console.log(response);
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data?.errors?.errorPayload?.error?.message === "INVALID_ID_TOKEN"
              || data.status === 407
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            } else if (data.statusCode === 404 && data?.errors?.errorMessage === LOGOUT_ERROR) {
              LogoutInvalidToken();
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const abort = () => controller.abort();

  return {
    post: (url, headerConfig = {}, body) => {
      return request(url, HTTP_METHODS.POST, headerConfig, body);
    },
    get: (url, customerToken, isDownload) => {
      return mainRequest(url, HTTP_METHODS.GET, customerToken, isDownload);
    },
    put: (url, customerToken, headerConfig = {}, body, app) => {
      console.log("customer", customerToken, app);
      return putRequest(
        url,
        HTTP_METHODS.PUT,
        headerConfig,
        customerToken,
        body,
        app
      );
    },
    delete: (url, headerConfig = {}) => {
      return request(url, HTTP_METHODS.DELETE, headerConfig);
    },
    abort: () => {
      abort();
      controller = new AbortController();
    },
  };
})();

export default MicroserviceFetchUtil;
