
import { GA_DATA_PARAMS } from '../common/gaEventConstants';
import configData from '../common/configData.json';
import gaLibrary from '../assets/scripts/ga-init.js';
import { UAParser }  from 'ua-parser-js';

const GaEventHelper = {

  init: async function() {
    const GTM_CODE = configData.GTM_CONTAINER_NUMBER.CODE;
    await gaLibrary.init(window,document,'script','dataLayer',GTM_CODE);
    console.log("GA initiated");
  },
  
  gaCommonEvent: data => {
        console.log("gaEvent", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.selectedParameter]: data.selectedParameter,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaSelectReportEvent: data => {
        console.log("gaEvent1", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [data.reportType]: data.reportName,
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaApplyFiltertEvent: data => {
        console.log("gaEvent2", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.Filter_type_applied]: data.Filter_type_applied,
        [GA_DATA_PARAMS.report_type]: data.reportType,
        [GA_DATA_PARAMS.report_name]: data.reportName,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaDownloadReportEvent: data => {
      console.log("gaEvent3", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.report_type]: data.reportType,
        [GA_DATA_PARAMS.report_name]: data.reportName,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaProfileEvent: data => {
    console.log("gaEvent4", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]: data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.business_scope_assigment]: data.selectedBsa ? data.selectedBsa : data.userDetails?.bsa[0] || null,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaLoginPageEvent: data => {
    console.log("gaEvent5", data);
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.ucube_mail]: data.email.toLowerCase() || "",
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }catch (error){
      console.log(error);
    }
  },
  gaViewLoginEvent: data => {
    console.log("gaEvent6", data);
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }catch (error){
      console.log(error);
    }
  },
  gaLoggenInEvent: data => {
    console.log("gaEvent7", data);
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.lastLoginAt]: data.userDetails.lastLoginAt,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }catch (error){
      console.log(error);
    }
  },
  gahomepageEvent: data => {
  console.log("gaEvent8", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.lastLoginAt]: data.userDetails.lastLoginAt,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaSelectDateEvent: data => {
  console.log("gaEvent9", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.selectedParameter]: data.selectedValue,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaUcubeReportEvent: data => {
  console.log("gaEvent10", data);
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.ucube_mail]: data.userDetails.email.toLowerCase() || "",
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.viewedRole]: data.viewedRole,
        [GA_DATA_PARAMS.report_category]: data.report_category,
        [GA_DATA_PARAMS.hierarchyPath]: data.hierarchyPath,
        [GA_DATA_PARAMS.selectedParameter]: data.reportPeriod,
        [GA_DATA_PARAMS.report_section]: data.report_section,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaForgotPasswordRequestEvent: data => {
  console.log("gaEvent11", data);
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]: data.event,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.ucube_mail]: data.email.toLowerCase() || "",
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }catch (error){
      console.log(error);
    }
  },
  gaBackToLoginEvent: data => {
    console.log("gaEvent12", data);
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        ...deviceDetails()
      })
    }catch (error){
      console.log(error);
    }
  },
}

const deviceDetails = () =>{
  const userAgent = navigator?.userAgent;
  const parser = new UAParser(userAgent); // Use the test user-agent string
  const details = parser.getResult();
  return {
    [GA_DATA_PARAMS.deviceType]:  details?.device?.type || (window?.innerWidth < 768 ? "mobile" : "Desktop" ),
    [GA_DATA_PARAMS.deviceVendor]: details?.device?.vendor || null,
    [GA_DATA_PARAMS.deviceModel]: details?.device?.model || null ,
    [GA_DATA_PARAMS.deviceBrowser]: details?.browser?.name || null ,
    [GA_DATA_PARAMS.deviceOS]: details?.os?.name || null  ,
  }
}


export default GaEventHelper;