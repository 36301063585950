import React, { useEffect,createContext } from "react";
import { connect } from "react-redux";
import { getLevels } from "../../constants/constant-role";
import { getLevelsID } from "../../constants/constant-role-id";

import {
  updateUserDetailsAction,
  updateSelectedBsaAction,
  updateLevelAction,
  updateSelectedCountryCodeAction,
  updateDTAccessAction,
} from "./Actions/StoreActions";
import { getLevelsBD } from "../../constants/constant-role-bd";

const StoreContext = createContext("");

let StoreContextProvider = props => {
  const {
    userDetails,
    updateUserDetails,
    updateSelectedBsa,
    selectedBsa,
    updateCountryCode,
    selectedCountryCode,
    updateLevel,
    level,
    dtAccess,
    updateDTAccess,
  } = props;

  const value = {
    userDetails,
    updateUserDetails,
    updateSelectedBsa,
    selectedBsa,
    updateLevel,
    updateCountryCode,
    selectedCountryCode,
    level,
    dtAccess,
    updateDTAccess,
  };
  const sortArray = bsa => {
    const dummy = selectedCountryCode === "idic"  ? ["Ice Cream", "Distributor", "New Channel"] : ["Distributor", "New Channel", "Ice Cream"];
    const sortedArray = bsa.slice().sort((a, b) => 
      dummy.indexOf(a) - dummy.indexOf(b))
    return sortedArray;
  };
  const countryFunction = countries => {
    if (countries.length > 0) {
      for (const country of countries) {
        switch (country) {
          case "id":
            localStorage.setItem("isIndonesia", true);
            break;
          case "vn":
            localStorage.setItem("isVietnam", true);
            break;
          case "ph":
            localStorage.setItem("isPhilipines", true);
            break;
          case "th":
            localStorage.setItem("isThailand", true);
            break;
          case "pk":
            localStorage.setItem("isPakistan", true);
            break;
          case "bd":
            localStorage.setItem("isBangladesh", true);
            break;
          case "idic":
            localStorage.setItem("isIndonesiaIc", true);
            break;
          default:
            break;
        }
      }
    }
  };
  useEffect(() => {
    const bsa = userDetails && userDetails.bsa ? userDetails.bsa : [];
    const role = userDetails && userDetails.role ? userDetails.role : "CD VP";
    const countries =
      userDetails && userDetails.countryCode
        ? userDetails.countryCode.toLowerCase().split(",")
        : [];
    userDetails.dtAccessCodes
      ? updateDTAccess(userDetails.dtAccessCodes.split(","))
      : updateDTAccess([]);
    console.log("bsa", bsa);
    if (bsa.length > 0) {
      const sortedArray = sortArray(bsa);
      userDetails.bsa = sortedArray;
      updateSelectedBsa(sortedArray[0]);
    }
    if (role !== "") {
      if(selectedCountryCode === "bd") {
        updateLevel(getLevelsBD(role));
      }
       else if (selectedCountryCode === "id") {
        updateLevel(getLevelsID(role));
      } else {
        updateLevel(getLevels(role));
      }
    }
    countryFunction(countries);
  }, [updateDTAccess, updateLevel, updateSelectedBsa, userDetails]);

  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    userDetails: state.store.userDetails,
    selectedBsa: state.store.selectedBsa,
    level: state.store.level,
    selectedCountryCode: state.store.selectedCountryCode,
    dtAccess: state.store.dtAccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails(data) {
      dispatch(updateUserDetailsAction(data));
    },
    updateSelectedBsa(data) {
      dispatch(updateSelectedBsaAction(data));
    },
    updateLevel(data) {
      dispatch(updateLevelAction(data));
    },
    updateCountryCode(data) {
      dispatch(updateSelectedCountryCodeAction(data));
    },
    updateDTAccess(data) {
      dispatch(updateDTAccessAction(data));
    },
  };
};

StoreContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreContextProvider);

export { StoreContext, StoreContextProvider };
