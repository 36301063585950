import React, { useState, useEffect } from "react";
import Main from "./components/Main";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import ConfigHelper, { disableConsoleLogs } from "./helper/config-helper";
import { StoreContextProvider } from "./components/ContextStore/StoreContextProvider";
import { Provider as ReduxProvider } from "react-redux";
import store from "./components/ContextStore/Store/Store";
import { useThirdPartyInjection } from './hooks/useThirdPartyInjection';

function App() {
  useThirdPartyInjection();

  const [status, setStatus] = useState(false);
  const marketSpecificConfig = () => {
    const countryCode = ConfigHelper.getCountryCode();
    if (countryCode) {
      setStatus(true);
    }
  };

  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location = window.location;
    }

    marketSpecificConfig();
    disableConsoleLogs();
  }, []);
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <StoreContextProvider>
          <React.StrictMode>{status === true && <Main />}</React.StrictMode>
        </StoreContextProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;
