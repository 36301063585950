// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  background-color: #59b876 !important;
  width: 400px;
  top: 20px;
  right: 80px;
}

.Toastify__toast--success {
  background-color: #59b876 !important;
}
 
.Toastify__toast--error {
  background-color: #D52F3D !important;
}
 
.Toastify__zoom-enter {
  display: none !important;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__toast-body {
  font-size: 16px !important;
  /* padding: 20px !important; */
  color: white !important;
  margin-left: -10px;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .Toastify__toast {
    width: 380px;
    top: 0;
    right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/helper/Toast/toastify.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;EACZ,SAAS;EACT,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE;IACE,YAAY;IACZ,MAAM;IACN,QAAQ;EACV;AACF","sourcesContent":[".Toastify__toast {\n  background-color: #59b876 !important;\n  width: 400px;\n  top: 20px;\n  right: 80px;\n}\n\n.Toastify__toast--success {\n  background-color: #59b876 !important;\n}\n \n.Toastify__toast--error {\n  background-color: #D52F3D !important;\n}\n \n.Toastify__zoom-enter {\n  display: none !important;\n}\n\n.Toastify__progress-bar {\n  display: none;\n}\n\n.Toastify__close-button {\n  align-self: center;\n}\n\n.Toastify__toast-body {\n  font-size: 16px !important;\n  /* padding: 20px !important; */\n  color: white !important;\n  margin-left: -10px;\n  display: flex;\n  align-items: center;\n}\n\n@media (max-width: 480px) {\n  .Toastify__toast {\n    width: 380px;\n    top: 0;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
