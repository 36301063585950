import {
  SAMLAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  onAuthStateChanged,
  signInWithPopup
} from "firebase/auth";
import { auth } from "./init";
import configData from '../../src/common/configData.json';

export const samlProvider =  configData && configData.firebase && configData.firebase.samlProvider ? configData.firebase.samlProvider : "";

export const signInWithSAMLSSO = () => {
  const firebaseSAMLProvider = new SAMLAuthProvider(samlProvider);
  signInWithRedirect(auth, firebaseSAMLProvider);
};

export const signInWithSAMLSSOPopup = async () => {
  const firebaseSAMLProvider = new SAMLAuthProvider(samlProvider);
  if(auth){
    const userCred = await signInWithPopup(auth, firebaseSAMLProvider);
    return userCred;
  }
  return false;
}

export const signInSSOAuthState = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback(user);
    } else {
      callback(null);
    }
  });
};


export const signInSAMLResponseResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    return result;
  } catch (error) {
    return false;
  }
};

export const doSSOLogout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.log(error);
  }
};
