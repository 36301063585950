import {
  UPDATE_USER_DETAILS,
  UPDATE_SELECTED_BSA,
  UPDATE_LEVEL,
  UPDATE_SELECTED_COUNTRYCODE,
  UPDATE_DT_ACCESS,
  ADD_CHANGED_OUTLETSTATUS,
  REMOVE_CHANGED_OUTLETSTATUS,
  CLEAR_CHANGED_OUTLETSTATUSES,
} from "../Actions/storeActionTypes";

const initialState = {
  userDetails: {},
  selectedBsa: "",
  level: [],
  selectedCountryCode: "",
  dtAccess: "",
  changedStatuses: [],
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    }
    case UPDATE_SELECTED_BSA: {
      return {
        ...state,
        selectedBsa: action.payload.selectedBsa,
      };
    }
    case UPDATE_LEVEL: {
      return {
        ...state,
        level: action?.payload?.level,
      };
    }
    case UPDATE_SELECTED_COUNTRYCODE: {
      return {
        ...state,
        selectedCountryCode: action.payload.selectedCountryCode,
      };
    }
    case UPDATE_DT_ACCESS: {
      return {
        ...state,
        dtAccess: action.payload.dtAccess,
      };
    }
    case ADD_CHANGED_OUTLETSTATUS:
      return {
        ...state,
        changedStatuses: [...state.changedStatuses, action.payload],
      };
    case REMOVE_CHANGED_OUTLETSTATUS:
      return {
        ...state,
        changedStatuses: state.changedStatuses.filter(
            (status) => status.outletCode !== action.payload
        ),
      };
    case CLEAR_CHANGED_OUTLETSTATUSES:
      return {
        ...state,
        changedStatuses: [],
      };
    default:
      return state;
  }
};

export default storeReducer;
