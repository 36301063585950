// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brandingLoader_logo__yo5w\\+ {
  margin: auto;
  display: block;
  position: fixed;
  width: 49%;
  width: 10%;
  height: 10%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .brandingLoader_logo__yo5w\\+ .brandingLoader_demo__WtOBf {
    width: 50px; }
`, "",{"version":3,"sources":["webpack://./src/components/Brandingloader/brandingLoader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,UAAU;EACV,UAAU;EACV,WAAW;EACX,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS,EAAA;EAVX;IAaI,WAAW,EAAA","sourcesContent":[".logo {\n  margin: auto;\n  display: block;\n  position: fixed;\n  width: 49%;\n  width: 10%;\n  height: 10%;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0; // background-color: rgba(0, 0, 0, 0.1);    z-index: 900;\n\n  .demo {\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `brandingLoader_logo__yo5w+`,
	"demo": `brandingLoader_demo__WtOBf`
};
export default ___CSS_LOADER_EXPORT___;
