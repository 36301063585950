
import { GA_DATA_PARAMS } from '../common/gaEventConstants';
import configData from '../common/configData.json';
import gaLibrary from '../assets/scripts/ga-init.js';


const GaEventHelper = {

  init: async function() {
   

    const GTM_CODE = configData.GTM_CONTAINER_NUMBER.CODE;
    await gaLibrary.init(window,document,'script','dataLayer',GTM_CODE);
    console.log("GA initiated");
  },
  
  gaCommonEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaSelectReportEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [data.reportType]: data.reportName,
        [GA_DATA_PARAMS.timestamp]: new Date().getTime()
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaApplyFiltertEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.Filter_type_applied]: data.Filter_type_applied,
        [GA_DATA_PARAMS.report_type]: data.reportType,
        [GA_DATA_PARAMS.report_name]: data.reportName,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaDownloadReportEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.report_type]: data.reportType,
        [GA_DATA_PARAMS.report_name]: data.reportName,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
       
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaProfileEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]: data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.business_scope_assigment]: data.selectedBsa ? data.selectedBsa : data.userDetails?.bsa[0] || null,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaLoginPageEvent: data => {
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
        
      })
    }catch (error){
      console.log(error);
    }
  },
  gaLoggenInEvent: data => {
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.lastLoginAt]: data.userDetails.lastLoginAt,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      
      })
    }catch (error){
      console.log(error);
    }
  },
  gahomepageEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.lastLoginAt]: data.userDetails.lastLoginAt,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaSelectDateEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.selectedParameter]: data.selectedValue,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      })
    }
    }catch (error){
      console.log(error);
    }
  },
  gaUcubeReportEvent: data => {
    try{
      if(configData?.common[localStorage.getItem('countryCode')]?.enableGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        [GA_DATA_PARAMS.event]:data.event,
        [GA_DATA_PARAMS.user_id]: data.userDetails.id,
        [GA_DATA_PARAMS.screen_name]: data.screen_name,
        [GA_DATA_PARAMS.login_user_role]: data.userDetails.role,
        [GA_DATA_PARAMS.viewedRole]: data.viewedRole,
        [GA_DATA_PARAMS.report_category]: data.report_category,
        [GA_DATA_PARAMS.hierarchyPath]: data.hierarchyPath,
        [GA_DATA_PARAMS.reportPeriod]: data.reportPeriod,
        [GA_DATA_PARAMS.report_section]: data.report_section,
        [GA_DATA_PARAMS.registered_country]: configData.countryList[localStorage.getItem('countryCode')],
        [GA_DATA_PARAMS.timestamp]: new Date().getTime(),
      })
    }
    }catch (error){
      console.log(error);
    }
  },
}




export default GaEventHelper;