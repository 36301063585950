export const SUPERUSERLEVELS = [
  {
    levelname: "Super User",
    columnName: "Super User",
    emailId: "",
    active: false,
  },
  {
    levelname: "Branch Manager",
    columnName: "Branch Manager",
    emailId: "",
    active: true,
  },
  {
    levelname: "Area Sales Manager",
    columnName: "Area Sales Manager",
    emailId: "",
    active: false,
  },
  {
    levelname: "Supervisor",
    columnName: "Sales Supervisor",
    emailId: "",
    active: false,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const CDVPLEVELS = [
  {
    levelname: "CD VP",
    columnName: "CD VP",
    emailId: "",
    active: false,
  },
  {
    levelname: "Branch Manager",
    columnName: "Branch Manager",
    emailId: "",
    active: true,
  },
  {
    levelname: "Area Sales Manager",
    columnName: "Area Sales Manager",
    emailId: "",
    active: false,
  },
  {
    levelname: "Supervisor",
    columnName: "Sales Supervisor",
    emailId: "",
    active: false,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const BMLEVELS = [
  {
    levelname: "Branch Manager",
    columnName: "Branch Manager",
    emailId: "",
    active: false,
  },
  {
    levelname: "Area Sales Manager",
    columnName: "Area Sales Manager",
    emailId: "",
    active: true,
  },
  {
    levelname: "Supervisor",
    columnName: "Sales Supervisor",
    emailId: "",
    active: false,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const ASMLEVELS = [
  {
    levelname: "Area Sales Manager",
    columnName: "Area Sales Manager",
    emailId: "",
    active: false,
  },
  {
    levelname: "Supervisor",
    columnName: "Sales Supervisor",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const SUPLEVELS = [
  {
    levelname: "Supervisor",
    columnName: "Sales Supervisor",
    emailId: "",
    active: false,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: true,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const DTLEVELS = [
  {
    levelname: "DT Operator",
    columnName: "DT Operator",
    emailId: "",
    active: false,
  },
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: true,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const TLLEVELS = [
  {
    levelname: "Team Leader",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    columnName: "Sales Person",
    emailId: "",
    active: true,
  },
];

export const SUPERUSERDISTRIBUTORlEVELS = [
  {
    levelname: "Super User",
    role: "Super User",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const CDVPDISTRIBUTORlEVELS = [
  {
    levelname: "CD VP",
    role: "CD VP",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const BMDISTRIBUTORlEVELS = [
  {
    levelname: "Branch Manager",
    role: "Branch Manager",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const ASMDISTRIBUTORlEVELS = [
  {
    levelname: "Area Sales Manager",
    role: "Area Sales Manager",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const SUPDISTRIBUTORlEVELS = [
  {
    levelname: "Supervisor",
    role: "Supervisor",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const DTOPERATORLEVELS = [
  {
    levelname: "DT Operator",
    role: "DT Operator",
    columnName: "DT Site Code",
    emailId: "",
    active: false,
  },
  {
    levelname: "Distributor",
    role: "LEVEL2",
    columnName: "DT Site Code",
    emailId: "",
    active: true,
  },
  {
    levelname: "Team Leader",
    role: "LEVEL3",
    columnName: "Team Leads",
    emailId: "",
    active: false,
  },
  {
    levelname: "Salesman",
    role: "LEVEL4",
    columnName: "Sales Person",
    emailId: "",
    active: false,
  },
];

export const getLevels = (role) => {
  switch (role) {
    case "Super User": {
      return SUPERUSERLEVELS;
    }
    case "CD VP": {
      return CDVPLEVELS;
    }
    case "Branch Manager": {
      return BMLEVELS;
    }
    case "Area Sales Manager": {
      return ASMLEVELS;
    }
    case "Supervisor": {
      return SUPLEVELS;
    }
    case "DT Operator": {
      return DTLEVELS;
    }
    case "Team Leader": {
      return TLLEVELS;
    }
    default: {
      return CDVPLEVELS;
    }
  }
};

export const getDistributorLevels = (role) => {
  switch (role) {
    case "Super User": {
      return SUPERUSERDISTRIBUTORlEVELS;
    }
    case "CD VP": {
      return CDVPDISTRIBUTORlEVELS;
    }
    case "Branch Manager": {
      return BMDISTRIBUTORlEVELS;
    }
    case "Area Sales Manager": {
      return ASMDISTRIBUTORlEVELS;
    }
    case "Supervisor": {
      return SUPDISTRIBUTORlEVELS;
    }
    case "DT Operator": {
      return DTOPERATORLEVELS;
    }
    default: {
      return CDVPDISTRIBUTORlEVELS;
    }
  }
};