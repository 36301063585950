var gaLibrary = (function(){
    
    return{
        init: function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.defer=true;j.fetchpriority="low";j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        
            //window,document,'script','dataLayer',`${contaierCode}`;
            console.log("GA Started");
            //Adding nonscript Tag
            var noscriptData = document.createElement("noscript");
            document.body.appendChild(noscriptData);
            var ifrm = document.createElement('iframe');
            noscriptData.appendChild(ifrm);
            ifrm.style.width = "0";
            ifrm.style.height = "0";
            ifrm.style.display = "none";
            ifrm.style.visibility = "hidden";
            ifrm.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${i}`);//containerCode
        }
    };
}());

export default gaLibrary;
