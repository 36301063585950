import Login from './Login';
import OperationalReports from './OperationalReports';
import OutletStatus from './OutletStatus';
import SalesmanDeviceId from './SalesmanDeviceId';

const COMMON ={
    ...Login,
    ...OperationalReports,
    ...OutletStatus,
    ...SalesmanDeviceId
}

export default COMMON;