import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import closeIcon from "../../assets/images/popupclose.svg";
import ErrorCloseIcon from "../../assets/images/WhiteCloseIcon.png";
import styles from "./CustomToast.module.scss";
 
const CustomCrossIcon = ({ isSuccess = true}) => {
  return (
    <div className={styles["close-button-container"]}>
      <CountdownCircleTimer
        isPlaying
        duration={5}
        size={50}
        strokeWidth={1.5}
        trailColor={isSuccess ? "#59B876" : "#D52F3D"}
        className="close-container"
        colors={isSuccess ? "#000000" : "#FFFFFF"}
      >
        {({ remainingTime }) => (
          <div className="icon-container">
            {isSuccess ? <img src={closeIcon} alt="close" />
                       : <img src={ErrorCloseIcon} alt="close" />
            }
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
};
 
export default CustomCrossIcon;