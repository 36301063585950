import { LogoutInvalidToken, RefreshTokenReload, getCookie } from '../utils/utils';
import configData from './configData.json';
// const configObj = globalConfig.get('configsGlobal');

const HTTP_METHODS = Object.freeze({
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
});

const MuleFetchUtil = (() => {
    const dataCache = {};

    const defaultConfig = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    let controller = new AbortController();

    const stringToHex = (str) => {
        var arr = [];
        for (var i = 0; i < str.length; i++) {
            arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
        }
        return arr.join('');
    };

    const request = (url, method, headerConfig, body,actionType) => {
        console.log("countryCode1111", headerConfig);
        const clientConfigs = {
            "client_id": configData.mulesoftConfig.client_id,
            "client_secret":configData.mulesoftConfig.client_secret,
         "countryCode": headerConfig.countryCode,
         "customerToken":headerConfig.customerToken
        };
        if (headerConfig.countryCode === "idic") {
            headerConfig.countryCode = "id";
            clientConfigs.countryCode = headerConfig.countryCode;
            clientConfigs.businessGroup = "ic";
          }
        const headers = {
          ...defaultConfig,
          ...clientConfigs,
          "action":actionType

        };
        const requestConfig = {
            method,
            headers,
            signal: controller.signal,
        };
        console.log(headers,requestConfig)
        if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
            requestConfig.body =JSON.stringify(body)
        }
        if(method===HTTP_METHODS.POST && (actionType==="refreshToken" || actionType==="logout")){
            requestConfig.headers={
                ...headers,"Customertoken":getCookie("idToken")
            }
        }
        const finalURL = `${configData.mulesoftConfig.muleEndpoint}${url} `
        const req = new Request(finalURL, requestConfig);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            return response.json();
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data.errors && data.errors.errorPayload && data.errors.errorPayload.error && data.errors.errorPayload.error.message ==="INVALID_ID_TOKEN" ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(error);
                });
        });
    };

    const abort = () => controller.abort();

    return {
        post: (url, headerConfig = {}, body,actionType) => {
            console.log("logout", headerConfig);
            return request(url, HTTP_METHODS.POST, headerConfig, body,actionType);
        },
        get: (url, headerConfig = {}) => {
            return request(url, HTTP_METHODS.GET, headerConfig);
        },
        put: (url, headerConfig = {}, body) => {
            return request(url, HTTP_METHODS.PUT, headerConfig, body);
        },
        delete: (url, headerConfig = {}) => {
            return request(url, HTTP_METHODS.DELETE, headerConfig);
        },
        abort: () => {
            abort();
            controller = new AbortController();
        }
    }
})();

export default MuleFetchUtil;