export const GA_EVENTS = Object.freeze({
    login:'login',
    view_login:'view_login',
    select_operational_report: 'select_operational_report',
    view_operational_report: 'view_operational report',
    select_Ucube:'select_Ucube',
    select_transactional_report: 'select_transactional_report',
    View_transactional_report: 'View_transactional_report',
    select_master_reports: 'select_master_reports',
    view_master_reports: 'view_master_reports',
    download_report: 'download_report',
    filter_apply: 'filter_apply',
    select_home_icon: 'select_home_icon',
    home_view: 'home_view',
    homecsreen_load: 'homecsreen_load',
    go_back_navigation: 'go_back_navigation',
    Profile_view: 'Profile_view',
    select_date_ucube: 'select_date_ucube',
    performance: 'performance',
    assortment: 'assortment',
    select_team_view: 'select_team_view',
    select_view_team_details: 'select_view_team_details',
    view_teams_details: 'view_teams_details',
    select_distributor: 'select_distributor',
    select_view_distributor_details: 'select_view_distributor_details',
    view_distributor_details: 'view_distributor_details',
    select_disciplinary_KPIs:"select_disciplinary_KPI's",
    view_disciplinaryKPIs_details: "view_disciplinaryKPI's_details",
    select_viewDetails_disciplinaryKPI: 'select_viewDetails_disciplinaryKPI',
    goback_to_DT_view: 'goback_to_DT_view',
    goback_to_KPI_view: 'goback_to_KPI_view',
    goback_to_teamview: 'goback_to_teamview',
    logout: 'logout',
    logout_view: 'logout_view',
    forgot_password: 'forgot_password',
    select_continue_with_SSO: 'select_continue_with_SSO',
    otp_request: 'otp_request',
    view_report_ucube: 'view_report_ucube',
    select_ucube_tabs: ['select_team_view', 'select_distributor', "select_disciplinary_KPI's"],
    loggedIn: 'loggedIn',
    Select_country: 'Select_country',
    begin_download_report: 'begin_download_report'

});

export const GA_DATA_PARAMS = Object.freeze({
    event: 'event',
    user_id: 'user_ucube',  
    screen_name: 'screen_name',
    login_user_role: 'login_user_role',
    registered_country: 'registered_country',
    timestamp: 'timestamp',
    transactional_report_name: 'transactional_report_name',
    master_report_name: 'master_report_name',
    report_type: 'report_type',
    report_name: 'report_name',
    downloaded_date_and_time: 'downloaded_date_and_time',
    count_of_downloads: 'count_of_downloads',
    Filter_type_applied: 'Filter_type_applied',
    business_scope_assigment: 'business_scope_assigment',
    lastLoginAt:'lastLoginAt',
    selectedParameter:'selectedParameter',
    viewedRole: 'viewedRole',
    report_category: 'report_category',
    reportLevel: 'reportLevel',
    hierarchyPath: 'hierarchyPath',
    reportPeriod: 'reportPeriod',
    report_section: 'report_section'
    });