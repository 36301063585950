import FileSaver from "file-saver";
import { isSpecialBDCountryCodes, isSpecialCountryCode } from "./CountryHelper";

export const getFileNameWithTimestamp = (fileName) => {
  const fileExtension = fileName
    .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
  return `${fileName
    .split(".")
    .slice(0, -1)
    .join(".")}_${Date.now()}.${fileExtension}`;
};

export const filterNonBlankRows = (rows) => {
  const filteredRows = rows.filter((row) => {
    const columns = row.split(",").map((col) => col.trim());
    return columns.some((col) => col !== "");
  });
  return filteredRows.join("\n");
};

export const getNonBlankRowsFile = (rows, file) => {
  const filteredContent = filterNonBlankRows(rows);
  return new Blob([filteredContent], { type: file.type });
};


export const downloadTemplate = (fileBase64, type ='csv') => {
  let byteCharacters = atob(fileBase64);
  let bytesLength = byteCharacters.length;
  let size = 1024;
  let slicesCount = Math.ceil(bytesLength / size);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let start = sliceIndex * size;
    let end = Math.min(start + size, bytesLength);
    let bytes = new Array(end - start);
    for (let offset = start, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  FileSaver.saveAs(new Blob(byteArrays), `Template.${type}`);
}

export const redirectIfInvalidConfig = ({ configData, selectedCountryCode, offlineApp, history }) => {
  const isOfflineAppEnabled = configData?.common[selectedCountryCode]?.OfflineApp;
  if (!isOfflineAppEnabled || !offlineApp) {
    history.push("/adminconfigurationpage");
  }
};

export const isSalesRole = (currentRole) => {
  return currentRole === "Salesman" || currentRole === "Sales Officer";
}

export const shouldRenderOfflineApp = (configData, selectedCountryCode, offlineApp) => {
  return configData?.common[selectedCountryCode]?.OfflineApp && offlineApp;
};

export const getHeaderNameWithCurrency = (column, selectedCountryCode) => {
  if (!column || !column.headerName || !selectedCountryCode) {
    return column ? column.headerName : '';
  }

  const currencySymbols = {
    vn: "₫",
    ph: "₱",
    th: "฿",
    bd: "TK",
  };

  const currencySuffix = currencySymbols[selectedCountryCode] || "";

  const specialHeaders = [
    "Invoice SKU Price",
    "Invoice SKU Amount",
    "Invoice SKU Return Price",
    "Invoice SKU Return Amount",
  ];

  if (specialHeaders.includes(column.headerName)) {
    return `${column.headerName} (${currencySuffix})`;
  }

  return column.headerName;
};

export const getRoleBasedOnCountryCode = (countryCode) => {
  if (!countryCode) return "Branch Managers"; 
  
  if (isSpecialCountryCode(countryCode)) {
      return "RSD";
  } 
  
  if (isSpecialBDCountryCodes(countryCode)) {
      return "AM";
  }
  
  return "Branch Managers";
}

