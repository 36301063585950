import FileSaver from "file-saver";

export const getFileNameWithTimestamp = (fileName) => {
  const fileExtension = fileName
    .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
  return `${fileName
    .split(".")
    .slice(0, -1)
    .join(".")}_${Date.now()}.${fileExtension}`;
};

export const filterNonBlankRows = (rows) => {
  const filteredRows = rows.filter((row) => {
    const columns = row.split(",").map((col) => col.trim());
    return columns.some((col) => col !== "");
  });
  return filteredRows.join("\n");
};

export const getNonBlankRowsFile = (rows, file) => {
  const filteredContent = filterNonBlankRows(rows);
  return new Blob([filteredContent], { type: file.type });
};


export const downloadTemplate = (fileBase64, type ='csv') => {
  let byteCharacters = atob(fileBase64);
  let bytesLength = byteCharacters.length;
  let size = 1024;
  let slicesCount = Math.ceil(bytesLength / size);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let start = sliceIndex * size;
    let end = Math.min(start + size, bytesLength);
    let bytes = new Array(end - start);
    for (let offset = start, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  FileSaver.saveAs(new Blob(byteArrays), `Template.${type}`);
}