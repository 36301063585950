import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import English from "../localization/English";

import Vietnam from "../localization/Vietnam";
import { getLanguageCode } from "../utils/utils";

const resources = {
  en: { translation: English },
  vn: { translation: Vietnam },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: resources,
  lng: getLanguageCode(),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
